<template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <div class="container">
      <div class="con-l">
        <div class="mod1 flex flex-v">
          <div class="s-pub-tt s-pub-ttt flex">
            <span class="bt">党员</span>
          </div>
          <div class="chart flex-11" ref="chart1"></div>
        </div>
        <div class="mod2 flex flex-v">
          <div class="s-pub-tt s-pub-ttt flex">
            <span class="bt">开展活动数量</span>
          </div>
          <div class="chart flex-11" ref="chart2"></div>
        </div>
        <div class="mod7 flex flex-v">
          <div class="s-pub-tt s-pub-ttt flex">
            <span class="bt">志愿者</span>
          </div>

          <div class="s-scroll-list flex-1 flex flex-v">
            <div class="row row-t">
              <div style="width: 37%">{{ volunteerAbout.head1 }}</div>
              <div style="width: 28%">{{ volunteerAbout.head3 }}</div>
            </div>
            <vue-seamless-scroll
              :data="volunteerList"
              :class-option="scrollOption"
              class="flex-1"
            >
              <div class="scrollbox">
                <div
                  class="row row-b"
                  v-for="(item, index) in volunteerList"
                  :key="index"
                >
                  <div style="width: 37%">{{ item.title }}</div>
                  <div style="width: 28%">{{ item.status }}</div>
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
      <div class="con-c flex flex-v relative">
        <!-- <div class="cen-menu">
            <span class="lk">新时代文明</span>
            <span class="lk cur">智慧党建</span>
            <span class="lk">智慧文旅</span>
            <span class="lk">双中心</span>
          </div> -->
        <ul class="cen-list cen-list-1">
          <li>
            <h6 class="title">党员数量<em>(人)</em></h6>
            <div class="txt">{{ partyMemberNum }}</div>
          </li>
          <li>
            <h6 class="title">支部数量<em>(个)</em></h6>
            <div class="txt">{{ zhiNum }}</div>
          </li>
          <li>
            <h6 class="title">积极分子<em>(人)</em></h6>
            <div class="txt">{{ activistNum }}</div>
          </li>
        </ul>
        <ul class="cen-list cen-list-2 b">
          <li class="item1">
            <h6 class="title">
              开展宣传活动<em>{{ wpr }}次</em>
              <!-- <img src="../assets/images/dangjian_up.png" alt="" /> -->
            </h6>
            <!-- <div class="txt">998,820</div> -->
          </li>
          <li class="item2">
            <h6 class="title">
              开展志愿活动<em>{{ we }}次</em>
              <!-- <img src="../assets/images/dangjian_up.png" alt="" /> -->
            </h6>
            <!-- <div class="txt">373,860</div> -->
          </li>
        </ul>
      </div>
      <div class="con-r flex flex-v">
        <div class="mod mod3">
          <div class="s-pub-tt s-pub-ttt flex">
            <span class="bt">活动剪影</span>
          </div>
          <ul class="mod3_list">
            <li class="mod3_item">
              <div class="tit" title="党员志愿者参与小区卫生大扫除">
                党员志愿者参与小区卫生大扫除
              </div>
              <div class="cont">
                <img
                  class="mod3_img"
                  src="https://home/ResImg/dj_01.jpg"
                  alt=""
                />
              </div>
            </li>
            <li class="mod3_item">
              <div class="tit" title="党员志愿者参加疫情防控工作">
                党员志愿者参加疫情防控工作
              </div>
              <div class="cont">
                <img
                  class="mod3_img"
                  src="https://home/ResImg/di_02.jpg"
                  alt=""
                />
              </div>
            </li>
            <li class="mod3_item">
              <div class="tit" title="新亨镇“传承雷锋精神 弘扬时代新风”志愿服务活动">
                新亨镇“传承雷锋精神 弘扬时代新风”志愿服务活动
              </div>
              <div class="cont">
                <img
                  class="mod3_img"
                  src="https://home/ResImg/dj_03.jpg"
                  alt=""
                />
              </div>
            </li>
            <li class="mod3_item">
                <div class="tit" title="小区功能型党支部开展主题党日活动">
                  小区功能型党支部开展主题党日活动
                </div>
                <div class="cont">
                  <img
                    class="mod3_img"
                    src="https://home/ResImg/dj_04.jpg"
                    alt=""
                  />
                </div>
            </li>
            <li class="mod3_item">
              <div class="tit" title="党员志愿者参与小区疫情防控工作">
                党员志愿者参与小区疫情防控工作
              </div>
              <div class="cont">
                <img
                  class="mod3_img"
                  src="https://home/ResImg/dj_05.jpg"
                  alt=""
                />
              </div>
            </li>
            <li class="mod3_item">
              <div class="tit" title="党员志愿者参与劳动节植树活动">
                党员志愿者参与劳动节植树活动
              </div>
              <div class="cont">
                <img
                  class="mod3_img"
                  src="https://home/ResImg/dj_06.jpg"
                  alt=""
                />
              </div>
            </li>
            <li class="mod3_item">
              <div class="tit" title="党员志愿者参与小区疫情防控工作">
                党员志愿者参与小区疫情防控工作
              </div>
              <div class="cont">
                <img
                  class="mod3_img"
                  src="https://home/ResImg/dj_07.jpg"
                  alt=""
                />
              </div>
            </li>
            <li class="mod3_item">
              <div class="tit" title="小区功能型党支部开展主题党日活动">
                小区功能型党支部开展主题党日活动
              </div>
              <div class="cont">
                <img
                  class="mod3_img"
                  src="https://home/ResImg/dj_08.jpg"
                  alt=""
                />
              </div>
            </li>
            <li class="mod3_item">
              <div class="tit" title="党员志愿者参与小区卫生大扫除">
                党员志愿者参与小区卫生大扫除
              </div>
              <div class="cont">
                <img
                  class="mod3_img"
                  src="https://home/ResImg/dj_09.jpg"
                  alt=""
                />
              </div>
            </li>
            <li class="mod3_item">
              <div class="tit" title="党员志愿者参与小区疫情防控工作">
                党员志愿者参与小区疫情防控工作
              </div>
              <div class="cont">
                <img
                  class="mod3_img"
                  src="https://home/ResImg/dj_10.jpg"
                  alt=""
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/head_dj.vue";
let Echarts = require("echarts/lib/echarts");
import { borderBox1 } from "@jiaminghi/data-view";
import Vue from "vue";
Vue.use(borderBox1);
import { loopShowTooltip } from "../assets/js/tooltip-auto-show-vue.js";
import axios from "axios";
export default {
  data() {
    return {
      headTitle: "",
      // headTitle: "岳阳南湖新区智慧党建",
      webData: {},
      mySwiper3: [],
      serviceAbout: {},
      munberList: [],
      volunteerAbout: {},
      volunteerList: [],
      volunteerList1: [
        {
          title: "xxx文明事件",
          status: "处理中",
        },
        {
          title: "xxx文明事件",
          status: "处理中",
        },
        {
          title: "xxx文明事件",
          status: "处理中",
        },
        {
          title: "xxx文明事件",
          status: "处理中",
        },
      ],
      partyMemberNum: "",
      zhiNum: "",
      activistNum: "",
      mumberNum: "",
      partyBranchNum: "",
      wpr: "",
      we: "",
      scroll_dang: [],
      scrollList: [],
      chart1: null,
      tootipTimer1: "",
      chart2: null,
      tootipTimer2: "",
    };
  },
  components: {
    Head,
  },
  computed: {
    scrollOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {
    clearTimeout()
    this.getData();
    this.getServiceAbout();
    this.getmunberList();
    this.getvolunteerAbout();
  },
  mounted() {
    this.mySwiper3 = new Swiper(".swiper-container.mySwiper3", {
      slidesPerView: 4,
      spaceBetween: 20,
      speed: 3000,
      autoplay: true,
      loop: true,
    });
    setTimeout(()=>{
    this.init1();
    this.init2();
    },1000)
    
    // this.init3();
    // this.init4();
    // this.init5();
    // this.init6();
    window.addEventListener("resize", () => {
      this.chart1.resize();
      this.chart2.resize();
      this.mySwiper3.resize();
      // this.chart3.resize();
      // this.chart4.resize();
      // this.chart5.resize();
      // this.chart6.resize();
    });
  },

  methods: {
    getData() {
      axios
        .get("/10zhdj.json", {
          params: {
            // timestamp:new Date().getTime()
            random: Math.random(),
          },
        })
        .then((res) => {
          // 处理响应
          // console.log("数据:"+res.data);
          let dataObject = res.data; // 这里假设获取到的数据是一个 Object 类型的数据
          // console.log(dataObject.data);
          //
          let jsonString = JSON.stringify(dataObject); // 将 Object 类型的数据转换为字符串类型的 JSON 数据
          // console.log(jsonString) // 输出字符串类型的 JSON 数据
          let jsonObject = JSON.parse(jsonString);
          this.webData = jsonObject;
          //标题
        })
        .catch((error) => {
          console.log(error.response.status);
          console.log(error.response.data);
          console.log(error.response.headers);
        });
    },
    // 团
    async getmunberList() {
      const res = await axios.get("/10zhdj.json");
      let dataObject = res.data;
      console.log("res.data====>", res.data);
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      console.log("jsonObject=======>", jsonObject.bigTitle);
      this.headTitle = jsonObject.bigTitle;
      this.partyMemberNum = jsonObject.partyMemberNum;
      this.zhiNum = jsonObject.zhiNum;
      this.activistNum = jsonObject.activistNum;
      this.mumberNum = jsonObject.mumberNum;
      this.wpr = jsonObject.wpr;
      this.we = jsonObject.we;
      // this.config.data = jsonObject.partyBranchNum
      // this.config = {...this.config}
      console.log("this.headTitle==>", this.headTitle);
      this.munberList = jsonObject.munberList;
      console.log("this.munberList===>", this.munberList);
    },
    // 志愿者
    async getvolunteerAbout() {
      const res = await axios.get("/10zhdj.json");
      let dataObject = res.data;
      console.log("res.data====>", res.data);
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.volunteerAbout = jsonObject.volunteerAbout;
      this.volunteerList = jsonObject.volunteerAbout.volunteerList;
    },
    // 服务列表
    async getServiceAbout() {
      const res = await axios.get("/10zhdj.json");
      let dataObject = res.data;
      console.log("res.data====>", res.data);
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.serviceAbout = jsonObject.serviceAbout;
      // this.scrollList = jsonObject.serviceAbout.serviceList;
      // this.config2.data = jsonObject.serviceAbout.serviceList
      console.log(this.scrollList);
    },
    init1() {
      this.chart1 = Echarts.init(this.$refs.chart1);
      let option = {
        title: {
          text: "单位为:人",
          right: nowSize(10),
          top: nowSize(1),
          textStyle: {
            fontSize: nowSize(22),
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.65)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        toolbox: {
          show: true,
        },
        grid: {
          left: nowSize(10),
          right: nowSize(10),
          bottom: nowSize(5),
          top: nowSize(20),
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            //boundaryGap: false,
            data: [
              "2016年",
              "2017年",
              "2018年",
              "2019年",
              "2020年",
              "2021年",
              "2022年",
              "2023年",
            ],
            // data:chart6Year,
            axisPointer: {
              type: "",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: nowSize(20),
              },
              //rotate: '0',
              interval: 0,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#fff54f", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#edf2f8"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
            min: 0,
            max: 500,
            interval: 100,
            nameTextStyle: {
              color: "#fff",
              fontSize: nowSize(16),
              padding: [0, 0, 0, -20],
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff", //更改坐标轴文字颜色
                fontSize: nowSize(20), //更改坐标轴文字大小
              },
              //formatter: '{value}%'
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#fff54f", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#fff54f"],
                width: 1,
                type: "solid",
              },
            },
          },
        ],

        series: [
          {
            name: "",
            type: "bar",
            stack: "Total",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
                fontSize: nowSize(16),
              },
            },
            showBackground: false,
            barWidth: nowSize(30),
            data: [292, 219, 285, 256, 345, 258, 356, 332],
            itemStyle: {
              normal: {
                barBorderRadius: 0,
                color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#fff54f",
                  },
                  {
                    offset: 1,
                    color: "#fff54f",
                  },
                ]),
              },
            },
          },
        ],
      };
      this.chart1.setOption(option, true);

      this.tootipTimer1 && this.tootipTimer1.clearLoop();
      this.tootipTimer1 = 0;
      this.tootipTimer1 = loopShowTooltip(this.chart1, option, {});
    },
    init2() {
      this.chart2 = Echarts.init(this.$refs.chart2);
      let option = {
        title: {
          text: "单位为:次",

          right: nowSize(10),
          top: nowSize(1),
          textStyle: {
            fontSize: nowSize(22),
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.65)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        toolbox: {
          show: true,
        },
        grid: {
          left: nowSize(10),
          right: nowSize(10),
          bottom: nowSize(5),
          top: nowSize(20),
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            //boundaryGap: false,
            data: [
              "2016年",
              "2017年",
              "2018年",
              "2019年",
              "2020年",
              "2021年",
              "2022年",
              "2023年",
            ],
            // data:chart6Year,
            axisPointer: {
              type: "",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: nowSize(20),
              },
              //rotate: '0',
              interval: 0,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#fff54f", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#edf2f8"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
            min: 0,
            max: 500,
            interval: 100,
            nameTextStyle: {
              color: "#fff",
              fontSize: nowSize(16),
              padding: [0, 0, 0, -20],
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff", //更改坐标轴文字颜色
                fontSize: nowSize(20), //更改坐标轴文字大小
              },
              //formatter: '{value}%'
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#fff54f", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#fff54f"],
                width: 1,
                type: "solid",
              },
            },
          },
        ],

        series: [
          {
            name: "",
            type: "bar",
            stack: "Total",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
                fontSize: nowSize(16),
              },
            },
            showBackground: false,
            barWidth: nowSize(30),
            data: [296, 319, 285, 456, 345, 258, 356, 332],
            itemStyle: {
              normal: {
                barBorderRadius: 0,
                color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#fff54f",
                  },
                  {
                    offset: 1,
                    color: "#fff54f",
                  },
                ]),
              },
            },
          },
        ],
      };
      this.chart2.setOption(option, true);

      this.tootipTimer2 && this.tootipTimer8.clearLoop();
      this.tootipTimer2 = 0;
      this.tootipTimer8 = loopShowTooltip(this.chart2, option, {});
    },
  },
};
</script>

<style scoped>
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: url(https://home/ResImg/bg_zhihuidangjian.jpg) no-repeat center;
  background-size: cover;
  background-color: #ba0b0c;
}

.mod1 {
  height: 38%;
  margin-bottom: 1%;
}
.mod2 {
  height: 38%;
}
.mod7 {
  margin-top: 2%;
  height: 20%;
  margin-bottom: 1%;
}
.mod3 {
  height: 98%;
  margin-bottom: 2%;
}

.mod1 .chart,
.mod2 .chart {
  height: calc(100% - 40px);
  flex: 1;
}

.mod1 .s-pub-ttt,
.mod2 .s-pub-ttt,
.mod3 .s-pub-ttt,
.mod7 .s-pub-ttt {
  background: url(../assets/images/dangjian_02.png);
  background-size: 100% 100%;
  padding-left: 100px;
  padding-right: 25px;
  align-items: center;
  height: 74px;
  margin-bottom: 30px;
}

.mod3 .s-scroll-list .scrollbox {
  height: 100%;
}
.mod2 .s-scroll-list .scrollbox {
  height: 33%;
}

.mod7 .s-scroll-list .row-t {
  background-color: #ff6363;
  height: 50px;
  font-size: 32px;
  color: #fff;
  margin-bottom: 15px;
}
.mod7 .s-scroll-list .row > div {
  padding: 0 5px;
  font-size: inherit;
}
.mod7 .s-scroll-list .row-b {
  height: 60px;
  font-size: 28px;
  color: #fff;
}

.mod7 .s-scroll-list .scrollbox .row:nth-child(odd) {
  background-image: linear-gradient(
    -45deg,
    #c02000 10%,
    transparent 25%,
    transparent 50%,
    #c02000 25%,
    #c02000 35%,
    transparent 75%,
    transparent
  );
  background-size: 20px 20px;
  margin: 12px 0;
}

.mod7 .s-scroll-list .row > div:first-child {
  padding-left: 25px;
}

.mod2 {
}
.mod2 .list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 30px;
  height: 40%;
  margin-bottom: 30px;
}
.mod2 .list .item {
  position: relative;
  overflow: hidden;
}
.mod2 .list .title {
  font-size: 30px;
  color: #fff;
  text-align: center;
  line-height: 34px;
  padding: 15px 10px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #ff6363;
}

.mod2 .chart {
  height: calc(100% - 10px);
  flex: 1;
}
.mod {
  display: flex;
  flex-direction: column;
}
.mod .chart {
  height: calc(100% - 100px);
  flex: 1;
}
.mod3 .mod3_list {
  width: 100%;
  height: 84%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

}
.mod3 .mod3_list .mod3_item {
  width: 35%;
}

.mod3 .mod3_list .mod3_item .cont {
  box-shadow: 6px 6px 10px 5px #980606;
  border: 2px solid #980606;
  border-radius: 20px;
  overflow: hidden;
  height: 74%;
}
.mod3 .mod3_list .mod3_item .cont img {
  width: 100%;
  height: 100%;
}
.mod3 .mod3_list .mod3_item .tit {
  font-size: 28px;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%; */

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.cen-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0 5%;
  color: #fff;
}
.cen-list li {
  text-align: center;
  width: 33%;
  margin-top: 20px;
  margin-left: 10px;
}
.cen-list.cen-list-1 .title {
  font-size: 44px;
  font-family: "RuiZiZhenYan";
  height: 70px;
  line-height: 70px;
  background: url(../assets/images/dangjian_01.png);
  background-size: 100% 100%;
}
.cen-list.cen-list-1 .title em {
  font-size: 30px;
  font-family: "RuiZiZhenYan";
}
.cen-list.cen-list-1 .txt {
  font-size: 52px;
  color: #fff54f;
  font-family: "PangMenZhengDao";
  margin-top: 10px;
}

.cen-list-2.b {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 40px;
}
.cen-list.b li {
  width: 50%;
}
.cen-list.cen-list-2 .item1 {
  background: url(../assets/images/dangjian_04.png);
  background-size: 100% 100%;
}
.cen-list.cen-list-2 .item2 {
  background: url(../assets/images/dangjian_05.png);
  background-size: 100% 100%;
}
.cen-list.cen-list-2 .title {
  font-size: 40px;
  margin-left: 105px;
  line-height: 100px;
}
.cen-list.cen-list-2 .title em {
  font-size: 52px;
  color: #fff54f;
  font-family: "PangMenZhengDao";
  margin-top: 10px;
  margin-left: 30px;
}
.cen-list.cen-list-2 .title img {
  width: 30px;
  height: 30px;
}
.cen-map {
}
.cen-map .item {
  text-align: center;
}
.cen-map .title {
  font-size: 34px;
  color: #fff;
  margin-bottom: 15px;
}
.cen-map .tag {
  display: inline-block;
  vertical-align: middle;
  font-size: 26px;
  color: #fd9d20;
  border: 1px solid #fd9d20;
  border-radius: 30px;
  padding: 0 25px;
  line-height: 50px;
}
.cen-map .icon {
  width: 120px;
  display: block;
  margin: 20px auto 0;
}

.cen-map .item1 {
  position: absolute;
  left: 10%;
  top: 65%;
}
.cen-map .item2 {
  position: absolute;
  left: 25%;
  top: 45%;
}
.cen-map .item3 {
  position: absolute;
  left: 30%;
  top: 28%;
}
.cen-map .item4 {
  position: absolute;
  left: 40%;
  top: 58%;
}
</style>
